<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="userId">
            <user-select v-model="form.userId"></user-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        row-key="id"
        :row-selection="rowSelection"
        :scroll="{ x: scrollX }"
        @change="change"
        style="margin-bottom: 16px;"
      >
        <template slot="user" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.nickname">{{ record.nickname }}</a-tooltip>
        </template>
        <template slot="userAgent" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
        <template slot="detail" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
        </template>
        <template slot="success" slot-scope="record">
          <boolean-status-badge false-name="失败" true-name="成功" :value="record"></boolean-status-badge>
        </template>
      </a-table>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
        <a-pagination
          v-model="form.page"
          :pageSize="form.pageSize"
          :show-size-changer="true"
          :show-total="t => `共 ${t} 条`"
          :total="total"
          @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"
        ></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getLoginLogList } from '@/api/login-log'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import UserSelect from '@/components/select/UserSelect'

export default {
  name: 'LoginLog',
  components: {
    BooleanStatusBadge,
    UserSelect
  },
  data () {
    return {
      form: {
        userId: undefined,
        page: 1,
        pageSize: 10
      },
      columns: [
        { dataIndex: 'created_at', title: '登陆时间', width: 200 },
        { dataIndex: 'user', title: '姓名', width: 200, ellipsis: true, scopedSlots: { customRender: 'user' } },
        { dataIndex: 'ip_address', title: 'IP 地址', width: 200 },
        { dataIndex: 'user_agent', title: '用户代理', width: 300, ellipsis: true, scopedSlots: { customRender: 'userAgent' } },
        { dataIndex: 'detail', title: '详情', width: 200, ellipsis: true, scopedSlots: { customRender: 'detail' } },
        {
          dataIndex: 'success',
          title: '状态',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'success' },
          filters: [
            { text: '成功', value: 'true' },
            { text: '失败', value: 'false' }
          ],
          filterMultiple: false
        }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.userId) params.user_id = this.form.userId
      this.loading = true
      getLoginLogList(params).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.success = filters.success[0]
      this.fetch()
    }
  }
}
</script>
